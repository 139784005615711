export default {
	'survey-closed': 'Beklager, denne undersøkelsen er nå lukket.',
	'survey-no-products': 'Denne undersøkelsen har ingen produkter.',
	'survey-no-products-summary':
		'Hvis du er eieren av denne undersøkelsen, må du opprette produkter i dashbordet for å fortsette.',
	'survey-completed': 'Du har allerede fullført denne undersøkelsen.',
	'summary-placeholder': 'Hvis du mener at dette er en feil, må du kontakte personen som sendte deg denne lenken.',
	'gone-wrong': 'Hm, noe er galt.',
	'try-again': 'Prøv på nytt, eller kom tilbake senere.',
	'thank-you': 'Takk',
	'thank-you-summary': 'Undersøkelsen er fullført, og resultatene er sendt inn',
	'thank-you-summary-redirect': 'Du blir omdirigert om noen få sekunder.',
	'page-not-found': 'Noe er galt',
	'page-not-found-placeholder': 'Ops, ødelagt lenke',
	'tap-your-favorite': 'Trykk på avmerkingen for favoritten din',
	or: 'eller',
	'get-started-with-questions': 'Noen spørsmål for å komme i gang',
	continue: 'Fortsett',
	retry: 'Prøv på nytt',
	'tell-us-how-you-feel-about-this-product': 'Fortell oss hva du synes om dette produktet',
	begin: 'Start',
	'how-would-you-describe-this-product-to-a-friend': 'Hvordan vil du beskrive dette produktet for en venn?',
	'type-your-response-here': 'Skriv inn svaret ditt her',
	'sum-of-total-characters': '${SUM} av ${TOTAL} tegn',
	edit: 'Rediger',
	remove: 'Fjern',
	'how-do-you-feel-about-this-part': 'Hva synes du om denne delen?',
	'tap-on-an-area-and-choose-an-emoji': 'Trykk på et område og velg en emoji',
	'im-finished': 'Jeg er ferdig',
	maintenance: 'Vedlikehold',
	'maintenance-mode': 'Det utføres vedlikehold på Upsiide for øyeblikket. Kom tilbake snart.',
	'please-specify': 'Spesifiser',
	'monadic-split-statement':
		'På den neste skjermen viser vi deg en idé. Les den nøye, da vi kommer til å stille deg noen spørsmål om den.',
	disapproval: 'Misbilligelse',
	anger: 'Sinne',
	sad: 'Trist',
	joy: 'Glede',
	love: 'Kjærlighet',
	trust: 'Tillit',
	hope: 'Håp',
	proud: 'Stolt',
	amazed: 'Forbløffet',
	surprised: 'Overrasket',
	guilt: 'Skyldfølelse',
	curious: 'Nysgjerrig',
	jealous: 'Sjalu',
	anticipation: 'Forventning',
	optimistic: 'Optimistisk',
	anxious: 'Urolig',
	fear: 'Frykt',
	despair: 'Fortvilelse',
	disbelief: 'Vantro',
	cynicism: 'Kynisme',
	regret: 'Anger',
	disgust: 'Avsky',
	annoyed: 'Irritert',
	aggressive: 'Aggressiv',
	shocked: 'Sjokkert',
	choose: 'Velg {{amount}}',
	'up-to': 'Velg opptil {{amount}}',
	unlimited: 'Velg så mange du vil',
	'single-select': 'Velg én',
	number: 'Angi et tall',
	'number-between-min-max': 'Angi et tall mellom {{min}} og {{max}}',
	'number-min': 'Angi et tall {{min}} eller høyere',
	'number-max': 'Angi et tall {{min}} eller lavere',
	'open-ended-pledge':
		'Denne spørreundersøkelsen inneholder spørsmål du må svare med egne ord. Svarene dine blir kontrollert automatisk for å sikre at de oppfyller kravene våre. Samtykker du til å fortsette?',
	'open-ended-pledge-accept': 'Ja, jeg samtykker',
	'open-ended-pledge-decline': 'Nei, jeg samtykker ikke',
	'rh-1-label': 'Hvilken av følgende er ikke en idrett?',
	'rh-1-o-1': 'Basketball',
	'rh-1-o-2': 'Tennis',
	'rh-1-o-3': 'Kjeks',
	'rh-1-o-4': 'Hockey',
	'rh-2-label': 'Hvor mye er 2+3?',
	'rh-3-label': 'Hvilken av følgende er en frukt?',
	'rh-3-o-1': 'Melk',
	'rh-3-o-2': 'Sjokolade',
	'rh-3-o-3': 'Egg',
	'rh-3-o-4': 'Eple',
	'open-end-max-length': 'Beklager, svaret er for langt',
	'heatmap-click-image': 'Klikk på bildet for å legge til en kommentar',
	'heatmap-click-image-no-comments': 'Klikk et sted på bildet',
	'heatmap-click-image-product-card': 'Klikk hvor du vil legge igjen en kommentar',
	'heatmap-click-image-product-card--no-comment': 'Klikk et sted på kortet',
	'heatmap-type-comment': 'Skriv inn en kommentar',
	cancel: 'Avbryt',
	skip: 'Hopp over',
	ranked: 'Rangert',
	top: 'Topp',
	'tap-or-drag': 'Trykk eller dra og slipp for å rangere',
	unranked: 'Ikke rangert',
	reset: 'Tilbakestill',
	'no-more-options': 'Ingen flere valg å rangere',
	'rank-list-full': 'Rangeringslisten er full, fjern rangeringen til et av valgene',
	'click-on-the-image-to-make-it-bigger': 'Klikk på bildet for å forstørre',
	thinking: 'Tenker …',
	'ai-is-listening': 'KI lytter …',
	'please-tell-us-what-you-think': 'Fortell oss hva du mener om denne idéen …',
	'in-system-settings-open-privacy-and-security': 'Åpne Personvern og sikkerhet i systeminnstillingene',
	'allow-access-to-your-camera': 'Gi tilgang til kameraet ditt',
	'allow-access-to-your-microphone': 'Gi tilgang til mikrofonen din',
	'click-the-camera-icon-in-browser-bar': 'Klikk på kameraikonet oppe i nettleserfeltet',
	'we-would-love-for-you-to-participate': 'Vi vil gjerne at du deltar:',
	'your-video-will-only-be-used-internally':
		'Videoen din brukes bare for intern forskning og blir ikke delt med noen tredjeparter.',
	'video-question-is-powered-by-ai': 'Videospørsmålene er drevet av KI, som ikke krever noe menneskelig handling.',
	'responses-are-anonymous': 'Svarene dine er anonyme og ikke tilknyttet noen personopplysninger.',
	'do-you-want-to-continue-participate': 'Vil du fortsette å delta?',
	'skip-video-questions': 'Hopp over videospørsmål',
	'i-changed-my-mind': 'Jeg skiftet mening',
	'are-you-sure-you-want-to-skip': 'Er du sikker på at du vil hoppe over?',
	'to-continue-please-enable-camera-microphone':
		'Vi ber om tilgang til kameraet ditt og mikrofonen din, slik at vi kan fortsette. Gi tilgang til dem nå. Hvis du ikke gir tillatelse, må vi avslutte økten din.',
	'yes-audio-only': 'Ja, bare lyd',
	'yes-enable-access': 'Ja, gi tilgang',
	'no-end-survey': 'Nei, avslutt spørreundersøkelsen',
	'survey-contains-video-questions': 'Denne spørreundersøkelsen inneholder videospørsmål',
	'participate-video-response':
		'Hvis du velger å delta, blir du bedt om å lage et videosvar ved hjelp av kameraet ditt og mikrofonen din.',
	'i-got-it': 'Jeg forstår',
	'consent-to-video-responses':
		'Ved å klikke på «Fortsett» gir du samtykke til å gi videosvar, og du gir Upsiide og klientene våre en ikke-eksklusiv lisens til å bruke, reprodusere og distribuere videoen din for intern forskning og analyse. Dette samtykket er evigvarende, med mindre det trekkes tilbake skriftlig.',
};
