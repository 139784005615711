import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
// Localization
import { useTranslation } from 'react-i18next';
import api from 'src/utilities/api';
import PropTypes from 'prop-types';
import QuestionHelperText from 'src/components/shared/QuestionHelperText';
import QuestionLabel from 'src/components/elements/QuestionLabel';
import ImageZoom from 'src/components/new/ImageZoom';
import * as misc from 'src/utilities/misc';
import cn from 'src/utilities/bem-cn';
import { CONSTANTS } from 'src/utilities/constants';
import GlobalScrollIndicator from 'src/components/helpers/GlobalScrollIndicator';
import VideoPlayer from '../../../../components/elements/VideoPlayer';
import SingleSelect from './SingleSelect';
import MultiSelect from './MultiSelect';
import EmojiSelect from './EmojiSelect';
import OpenEnded from './OpenEnded';
import RankedQuestion from './RankedQuestion';
import Grid from './Grid';
import Heatmap from './Heatmap';
import maskingHelper from '../../../../utilities/maskingHelper';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import VideoGuidedQuestion from './VideoGuidedQuestion';
import './styles.scss';

const className = 'question';
const scrollContainerClass = 'scroll-container';
const buttonHeight = 68;
const el = (name) => cn(className, name);

const isSingle = (style) => style === 'single' || style === 'single-select';
const isOpenEnded = (style) => style === 'open-ended' || style === 'open-ended-text' || style === 'custom-age';
const isHeatMap = (style) => style === 'heatmap';
const isGuidedVideoQuestion = (style) => style === 'guided-video-question';
const isLogicBlock = (style) => style === 'logic-block';
const openEndLengthLimit = 1024;

const getQuarantineReasonSanitized = (moderationReasons = [], isStaticImage = false) => {
	const shouldQuarantine = moderationReasons.length;
	if (!shouldQuarantine && !isStaticImage) return null;
	const quarantineReason = [...moderationReasons, ...(isStaticImage ? ['static-image'] : [])]
		.join(':')
		.replace(/videoModeration[@0-9.]*:/gi, '');
	return `videoModeration:${quarantineReason}`;
};

const Question = ({
	value,
	onChange,
	question,
	answers,
	setAnswers,
	currentSection,
	handleResults,
	productId,
	productOrder,
	onImageLoad,
	resetRankedOptions,
	isLastQuestion,
}) => {
	const { label, style, options, hasOtherSpecifyOption, hasNoneOfTheAboveOption, randomizeOptions } = question ?? {};
	const dispatch = useDispatch();

	const questionIndex = useSelector(selectors.getQuestionIndex);

	const [openEndChanged, setOpenEndChanged] = useState(false);

	// None of the above
	const [noneOptionId, setNoneOptionId] = useState(0);

	// Other
	const [otherOptionId, setOtherOptionId] = useState('');
	const [otherValue, setOtherValue] = useState('');
	const [isOtherSelected, setisOtherSelected] = useState(false);
	const [shouldSkip, setShouldSkip] = useState(false);
	const [contentOverflow, setContentOverflow] = useState(false);

	// cached options for randomization
	const [orderedOptions, setOrderedOptions] = useState([]);

	// Settings
	const multipleSelect = misc.getQuestionSetting(question, 'multi-select') === 'true';
	const selectLimitRange = misc.getQuestionSetting(question, 'limit-type');
	const selectLimit = misc.getQuestionSetting(question, 'limit');

	const numericValueOnly = misc.getQuestionSetting(question, 'numeric') === 'true';
	const openEndNumericMin = misc.getQuestionSetting(question, 'numeric-min');
	const openEndNumericMax = misc.getQuestionSetting(question, 'numeric-max');

	// Selectors
	const previousSectionsAnswers = useSelector((state) => selectors.getAnswers(state));
	const study = useSelector((state) => selectors.getStudy(state));
	const { optionalVideoQuestions } = useSelector((state) => selectors.getMainState(state));

	const { t } = useTranslation('main');

	const elementRef = useRef(true);
	useEffect(() => {
		if (!question) return setShouldSkip(true);
		let previousSectionsSkippedVideoQuestions = false;
		Object.keys(previousSectionsAnswers).forEach((key) => {
			if (
				previousSectionsAnswers[key].type === 'guided-video-question' &&
				previousSectionsAnswers[key].value === 'SKIP'
			) {
				previousSectionsSkippedVideoQuestions = 'true';
			}
		});

		const skipVideoQuestion =
			window.sessionStorage.getItem('skipVideoQuestion') || previousSectionsSkippedVideoQuestions;

		if (isGuidedVideoQuestion(question?.style) && skipVideoQuestion === 'true') {
			setShouldSkip(true);
			return;
		}

		if (hasOtherSpecifyOption) {
			const otherOption = options.find((option) => option.isOtherSpecify);
			if (otherOption) {
				setOtherOptionId(otherOption.id.toString());
			}
		}

		if (hasNoneOfTheAboveOption) {
			const noneOption = options.find((option) => option.isNoneOfTheAbove);
			if (noneOption) {
				setNoneOptionId(noneOption.id.toString());
			}
		}
		const sortedOptions = options;
		// Randomize the order of the options if turned on
		let localOptions = maskingHelper.getOptions(sortedOptions, answers, productId, currentSection?.questions);
		if (randomizeOptions || question?.style === 'emoji') {
			localOptions = [...localOptions];

			// Get all fixed indexes, store, take out of the array
			const fixed = [];
			let i;
			for (i = localOptions.length; i--; ) {
				if (localOptions[i].lockOrder) {
					fixed.unshift({ index: i, option: localOptions[i] });
					localOptions.splice(i, 1);
				}
			}

			// randomize remaining items
			localOptions.sort(() => Math.random() - 0.5); // Perform your sort

			// Jam fixed ones back in
			for (i = 0; i < fixed.length; i += 1) {
				localOptions.splice(fixed[i].index, 0, fixed[i].option);
			}
		} else {
			// console.warn('not randomized', options)
		}

		const preSelectedValues = [];
		// check skip possibilities
		if (
			!localOptions?.length &&
			isOpenEnded(question?.style) === false &&
			isHeatMap(question?.style) === false &&
			isGuidedVideoQuestion(question?.style) === false &&
			isLogicBlock(question?.style) === false
		) {
			// skip
			setShouldSkip(true);
		}
		// pre-select and skip
		if (localOptions?.length === 1) {
			const [singleOption] = localOptions;
			const possibleMasked = sortedOptions.some((option) => option?.isMasked);
			const isOptionMasked = !sortedOptions.some((option) => option?.id === singleOption?.id);
			if (possibleMasked && (!singleOption?.isOtherSpecify || isOptionMasked)) {
				/*
				 * Autopunch (pre-select and skip) if
				 * 1 option
				 * and
				 * possible recalled
				 * and
				 * not LOCAL OTS
				 */
				preSelectedValues.push(String(localOptions[0]?.id));
				setShouldSkip(true);
			}
		}

		// prepare & set available options
		const availableOptions = localOptions.map((option) => String(option?.id));

		// save available options in answers
		if (isOpenEnded(question?.style) === false) {
			setAnswers((prevState) => {
				const key = productId ? `${question?.id}-${productId}` : question?.id;
				let prevStateValue = prevState[key]?.value || [];
				// if previous was single select, value won't be an array
				if (!Array.isArray(prevStateValue)) prevStateValue = [prevStateValue];
				return {
					...prevState,
					[key]: {
						productOrder,
						...prevState[key],
						value: [...prevStateValue, ...preSelectedValues],
						availableOptions,
						productId,
						sectionId: question?.sectionId,
					},
				};
			});
		}
		if (isGuidedVideoQuestion(question?.style)) {
			if (question?.sectionType === 'monadic_split') {
				setAnswers((prevState) => {
					const key = productId ? `${question?.id}-${productId}` : question?.id;
					return {
						...prevState,
						[key]: {
							productOrder,
							...prevState[key],
							value: null,
							type: 'guided-video-question',
							productId,
							sectionId: question?.sectionId,
						},
					};
				});
			}
		}
		if (isHeatMap(question?.style)) {
			if (!(question?.asset && question?.assetId !== 0) && question?.sectionType !== 'monadic_split') {
				setShouldSkip(true);
			}
			if (question?.sectionType === 'monadic_split') {
				const { products } = currentSection;
				const showImageOnly = misc.getQuestionSetting(question, 'show-image-only') === 'true' || false;
				if (showImageOnly) {
					let hasAsset = false;
					if (products[productOrder]?.fieldOneType === 'asset') {
						hasAsset = products[productOrder]?.fieldOne[0]?.url;
					} else if (products[productOrder]?.fieldTwoType === 'asset') {
						hasAsset = products[productOrder]?.fieldTwo[0]?.url;
					} else if (products[productOrder]?.fieldThreeType === 'asset') {
						hasAsset = products[productOrder]?.fieldThree[0]?.url;
					}
					if (!hasAsset) {
						setShouldSkip(true);
					}
				}
			}
		}

		if (isLogicBlock(question?.style)) {
			dispatch(
				actions.checkLogic({
					question,
					answers,
					currentQuestionIndex: questionIndex,
					study,
				}),
			);
		}

		// set final ordered options
		setOrderedOptions(localOptions);

		// eslint-disable-next-line
	}, [hasNoneOfTheAboveOption, hasOtherSpecifyOption, options, question, randomizeOptions]);

	useEffect(() => {
		if (shouldSkip) {
			handleResults();
		}
	}, [shouldSkip, answers, handleResults]);

	useEffect(() => {
		resetScrollContainerClasses();

		setTimeout(() => {
			if (productId) {
				const baseClassName = 'monadic-split-container';
				const container = document.querySelector(`.${baseClassName}`);
				if (container) {
					let isContentOverflowing = false;
					isContentOverflowing = container.scrollHeight - buttonHeight / 2 > window.innerHeight;
					setContentOverflow(isContentOverflowing);
				}
			}
		}, 300);
	}, [style]);

	const resetScrollContainerClasses = () => {
		setTimeout(() => {
			// Reset scroll container
			const scrollContainer = document.querySelector(`[class*='${scrollContainerClass}']`);
			if (scrollContainer) {
				scrollContainer.classList.remove('scrolled-to-bottom');
				scrollContainer.classList.remove('remove-scroll-indicator');
			}
		}, 300);
	};

	const userCanSelectAnotherInput = (style, value) =>
		style === 'single-select' || // For old surveys
		(!multipleSelect && value.length <= 1 && Array.isArray(value)) ||
		(!multipleSelect && value.length > 0 && !Array.isArray(value)) || // Multi-select setting false - Single Select
		(multipleSelect && !selectLimitRange) || // Multi-select setting true with no limit-type setting and no limit setting - Unlimited multi-select
		(multipleSelect && selectLimitRange === 'unlimited') || // Multi-select setting true with limit-type set to unlimited - Unlimited multi-select
		(multipleSelect && selectLimitRange && !selectLimit && value.length <= 1) || // Multi-select with limit-type setting but no limit setting - Multi-select default limit of 1
		(multipleSelect && selectLimitRange && selectLimit && value.length <= selectLimit); // Multi-select with limit-type setting and limit setting - Multi-select with set select limit

	const change = (value) => {
		let noneIndex = false;

		if (multipleSelect) {
			if (style === 'multi-select' && noneOptionId && value.length === 1 && value.includes(noneOptionId)) {
				noneIndex = true;
			} else if (style === 'multi-select' && noneOptionId && value.length > 1 && value.includes(noneOptionId)) {
				noneIndex = value.indexOf(noneOptionId);
				if (noneIndex === 0) {
					// Didn't select None
					// was selected, remove it
					value = value.splice(1);
				} else {
					// Selected None
					noneIndex = true;
					value = [noneOptionId];
				}
			}
		}

		if (userCanSelectAnotherInput(style, value)) {
			let sendOther = isOtherSelected;
			const otherOption = options.find((option) => option.isOtherSpecify);
			if (
				(otherOption?.id?.toString() || otherOptionId) &&
				value.includes(otherOption?.id?.toString() || otherOptionId)
			) {
				setisOtherSelected(true);
				setTimeout(() => {
					const otherInput = document.querySelector('#other-input-field');
					if (otherInput) {
						otherInput.focus();
					}
				});
				sendOther = true;
			} else if (otherOptionId) {
				setisOtherSelected(false);
				sendOther = false;
			}

			onChange(value, style, sendOther ? otherValue : '', sendOther ? otherOptionId : 0, noneIndex);
		} else {
			console.log('Hit max number of selects');
		}
	};

	const onOtherChange = (otherValue) => {
		setOtherValue(otherValue);
		onChange(value, style, otherValue, otherOptionId);
	};

	const onOpenEndChange = async (value) => {
		setOpenEndChanged(!!value);
		onChange(value, style);
	};

	const onRankedQuestionChange = (value) => {
		onChange(value, style);
	};

	const isOpenEndInvalid = (value) => {
		if (!openEndChanged) return false;

		if (value.length > openEndLengthLimit) return true;

		if (openEndNumericMin && parseFloat(value) < parseFloat(openEndNumericMin)) {
			return true;
		}
		if (openEndNumericMax && parseFloat(value) > parseFloat(openEndNumericMax)) {
			return true;
		}
		return false;
	};

	const renderAsset = () => {
		if (question?.asset) {
			if (question.asset?.mediaType === 'video') {
				return <VideoPlayer url={question?.asset?.url} />;
			}
			let imageAlignment = '';
			if (question.settings) {
				const imageAlignSetting = question.settings.find(
					(s) => s.label === CONSTANTS.imageAlignmentSettings.questionSection,
				);
				imageAlignment = imageAlignSetting && imageAlignSetting.value;
			}
			return (
				<ImageZoom className={`${el('question-image')} ${imageAlignment ? `${imageAlignment}` : 'center'}`}>
					<img
						alt=""
						src={misc.getAssetVariationUrl(question.asset, ['large', 'full', 'medium', 'thumbnail'])}
						onLoad={() => {
							if (typeof onImageLoad === 'function') {
								onImageLoad();
							}
						}}
					/>
				</ImageZoom>
			);
		}

		return null;
	};

	// Combines current section answers with the answers from all previous sections
	const allAnswers = Object.assign(answers || {}, previousSectionsAnswers || {});

	const setAssetPreviewModalUrl = (assetPreviewModalUrl) =>
		dispatch(actions.setAssetPreviewModalUrl(assetPreviewModalUrl));

	const getHtmlLabel = () => (
		<QuestionLabel
			study={study}
			label={label}
			answers={allAnswers}
			setAssetPreviewModalUrl={setAssetPreviewModalUrl}
			productId={productId}
			t={t}
		/>
	);

	const asyncVideoUpload = async (
		videoData,
		setLoadingVideo,
		disablePreviewPrompt,
		question,
		study,
		quarantineMap,
		isStaticImage,
		thumbnail,
		interactionMap,
		isAudioOnly,
		onNext,
		respondentToken,
		productId,
		responseId,
		isPreview,
		isLastQuestion,
	) => {
		try {
			console.log('Uploading video');
			setLoadingVideo(true);
			if (disablePreviewPrompt) {
				return setTimeout(() => {
					setLoadingVideo(false);
					onNext();
				}, 1500);
			}

			const fileToUpload = new File([videoData], 'videoname.webm', {
				type: videoData.type,
				lastModified: new Date(),
			});

			const signedUrl = await api.getSignedUrl(
				question?.id,
				{
					studyUuid: study.uuid,
					audienceUuid: study.audienceUuid,
					token: respondentToken,
					productId,
					mimeType: fileToUpload.type,
				},
				study.useServerlessSurvey || isPreview,
			);

			if (signedUrl?.data?.url) {
				const url = signedUrl?.data.url;
				await api.uploadVideo(url, fileToUpload, fileToUpload.type);
			}

			const shouldQuarantine = quarantineMap?.filter((moderation) => moderation?.shouldBeDisqualified);
			const quarantineReason = getQuarantineReasonSanitized(
				quarantineMap
					?.filter((moderation) => moderation?.shouldBeDisqualified)
					?.map(({ moderationReason }) => moderationReason),
				isStaticImage,
			);
			if (!disablePreviewPrompt) {
				await api.sendInteractionMediaResponse(
					responseId,
					question?.id,
					{
						fileInfo: {
							key: signedUrl?.data.key,
							type: fileToUpload.type,
						},
						videoThumbnail: thumbnail,
						interactionMap,
						videoFrame: isAudioOnly ? 'audioOnly' : '',
						studyUuid: study.uuid,
						audienceUuid: study.audienceUuid,
						respondentToken,
						productId,
						isPreview,
						inQuarantine: shouldQuarantine.length > 0 || isStaticImage,
						...(quarantineReason ? { quarantineReason } : {}),
					},
					study.useServerlessSurvey || isPreview,
				);
			}
			if (isLastQuestion) {
				onChange('OK', question?.style);
			}
			setLoadingVideo(false);
		} catch (error) {
			Sentry.captureException(error);
			console.log(error);
			setLoadingVideo(false);
			if (!isLastQuestion) {
				onChange('FAILED-TO-UPLOAD', question?.style);
			} else {
				onChange('SKIP', question?.style);
			}
			// setTimeout(() => {
			// 	terminate(`video-question-upload-failure`);
			// }, 1500);
		}
	};

	return (
		<div className={className} ref={elementRef}>
			{isSingle(style) && (
				<label className={isOtherSelected ? el('other-selected') : ''}>
					{getHtmlLabel()}
					{renderAsset()}
					<SingleSelect question={question} value={value} onChange={change} options={orderedOptions} />
				</label>
			)}
			{style === 'ranked' && (
				<div className={`label ${isOtherSelected ? el('other-selected') : ''}`}>
					{getHtmlLabel()}
					{renderAsset()}
					<RankedQuestion
						question={question}
						value={value}
						onChange={onRankedQuestionChange}
						options={orderedOptions}
						otherSelected={isOtherSelected}
						onOtherChange={onOtherChange}
						resetRankedOptions={resetRankedOptions}
						productId={productId}
					/>
				</div>
			)}
			{style === 'multi-select' && (
				<div className={`label ${isOtherSelected ? el('other-selected') : ''}`}>
					{getHtmlLabel()}
					{renderAsset()}
					<QuestionHelperText
						style={style}
						multipleSelect={multipleSelect}
						selectLimitRange={selectLimitRange}
						selectLimit={selectLimit}
					/>
					{multipleSelect && (
						<MultiSelect
							question={question}
							value={value}
							onChange={change}
							options={orderedOptions}
							otherSelected={isOtherSelected}
							onOtherChange={onOtherChange}
						/>
					)}
					{!multipleSelect && (
						<SingleSelect
							question={question}
							value={value}
							onChange={change}
							options={orderedOptions}
							otherSelected={isOtherSelected}
							onOtherChange={onOtherChange}
						/>
					)}
				</div>
			)}
			{style === 'emoji' && (
				<div className="label">
					{getHtmlLabel()}
					{renderAsset()}
					<QuestionHelperText
						style={style}
						multipleSelect={multipleSelect}
						selectLimitRange={selectLimitRange}
						selectLimit={selectLimit}
					/>
					<EmojiSelect
						t={t}
						value={value}
						onChange={change}
						options={orderedOptions}
						multipleSelect={multipleSelect}
					/>
				</div>
			)}

			{style === 'grid' && (
				<Grid
					question={question}
					t={t}
					answers={answers}
					setAnswers={setAnswers}
					currentSection={currentSection}
					handleResults={handleResults}
					productId={productId}
					productOrder={productOrder}
					getHtmlLabel={getHtmlLabel}
					renderAsset={renderAsset}
					onImageLoad={onImageLoad}
				/>
			)}

			{isGuidedVideoQuestion(style) && (
				<VideoGuidedQuestion
					onSkip={onChange}
					onSuccess={onChange}
					asyncVideoUpload={asyncVideoUpload}
					isLastQuestion={isLastQuestion}
					{...{ question, productId }}
				/>
			)}

			{isOpenEnded(style) && (
				<label>
					{getHtmlLabel()}
					{renderAsset()}
					<OpenEnded value={value} onChange={onOpenEndChange} settings={question.settings} />

					<QuestionHelperText
						style={style}
						errorState={isOpenEndInvalid(value)}
						numericValueOnly={numericValueOnly}
						openEndNumericMin={isOpenEndInvalid(value) && openEndNumericMin}
						openEndNumericMax={isOpenEndInvalid(value) && openEndNumericMax}
						openEndLengthLimit={isOpenEndInvalid(value) && value.length > openEndLengthLimit}
					/>
				</label>
			)}
			{isHeatMap(style) && (
				<label>
					{getHtmlLabel()}
					<Heatmap
						studyId={study.id}
						question={question}
						onChange={onChange}
						currentSection={currentSection}
						productOrder={productOrder}
					/>
				</label>
			)}
			<GlobalScrollIndicator show={contentOverflow} />
		</div>
	);
};

Question.propTypes = {
	t: PropTypes.func,
	value: PropTypes.any,
	onChange: PropTypes.any,
	question: PropTypes.any,
	study: PropTypes.any,
	answers: PropTypes.any,
	previousSectionsAnswers: PropTypes.any,
	setAnswers: PropTypes.any,
	currentSection: PropTypes.any,
	handleResults: PropTypes.any,
	productId: PropTypes.any,
	productOrder: PropTypes.any,
	setAssetPreviewModalUrl: PropTypes.any,
	onImageLoad: PropTypes.func,
	resetRankedOptions: PropTypes.bool,
	isLastQuestion: PropTypes.bool,
};

export default Question;
