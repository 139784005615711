export default {
	'survey-closed': 'Жао нам је, ова анкета је затворена.',
	'survey-no-products': 'Ова анкета нема ниједан производ.',
	'survey-no-products-summary':
		'Ако сте власник ове анкете, креирајте производе на командној табли да бисте наставили.',
	'survey-completed': 'Већ сте попунили ову анкету.',
	'summary-placeholder': 'Ако мислите да је то грешка, обратите се особи која вам је послала ову везу.',
	'gone-wrong': 'Хмм, нешто није у реду.',
	'try-again': 'Покушајте поново или пробајте касније.',
	'thank-you': 'Хвала вам',
	'thank-you-summary': 'Ваша анкета је завршена и резултати су послати',
	'thank-you-summary-redirect': 'Бићете преусмерени за неколико секунди.',
	'page-not-found': 'Нешто није у реду',
	'page-not-found-placeholder': 'Упс, прекинута веза',
	'tap-your-favorite': 'Додирните ознаку потврде на омиљеном',
	or: 'или',
	'get-started-with-questions': 'Неколико питања за почетак',
	continue: 'Настави',
	retry: 'Поново покушај',
	'tell-us-how-you-feel-about-this-product': 'Кажите нам шта мислите о овом производу',
	begin: 'Почни',
	'how-would-you-describe-this-product-to-a-friend': 'Како бисте описали овај производ пријатељу/пријатељици?',
	'type-your-response-here': 'Унесите одговор овде',
	'sum-of-total-characters': '${SUM} од ${TOTAL} знакова',
	edit: 'Измени',
	remove: 'Уклони',
	'how-do-you-feel-about-this-part': 'Шта мислите о овом делу?',
	'tap-on-an-area-and-choose-an-emoji': 'Додирните област и одаберите емоџи',
	'im-finished': 'Завршио/ла сам',
	maintenance: 'Одржавање',
	'maintenance-mode': 'Тренутно се изводи одржавање за Upsiide. Проверите поново ускоро.',
	'please-specify': 'Наведите',
	'monadic-split-statement':
		'На следећем екрану ћемо вам показати идеју. Пажљиво проучите јер ћемо имати питања о томе.',
	disapproval: 'Неслагање',
	anger: 'Бес',
	sad: 'Туга',
	joy: 'Радост',
	love: 'Љубав',
	trust: 'Поверење',
	hope: 'Нада',
	proud: 'Поносан/на',
	amazed: 'Задивљен(а)',
	surprised: 'Изненађен(а)',
	guilt: 'Кривица',
	curious: 'Радознао/ла',
	jealous: 'Љубоморан/на',
	anticipation: 'Очекивање',
	optimistic: 'Оптимистично',
	anxious: 'Забринуто',
	fear: 'Страх',
	despair: 'Очај',
	disbelief: 'Неверица',
	cynicism: 'Цинизам',
	regret: 'Жаљење',
	disgust: 'Гађење',
	annoyed: 'Изнервиран(а)',
	aggressive: 'Агресиван/на',
	shocked: 'Шокиран(а)',
	choose: 'Одаберите {{amount}}',
	'up-to': 'Одаберите до {{amount}}',
	unlimited: 'Одаберите колико желите',
	'single-select': 'Одаберите један',
	number: 'Унесите број',
	'number-between-min-max': 'Унесите број између {{min}} и {{max}}',
	'number-min': 'Унесите број {{min}} или већи',
	'number-max': 'Унесите број {{max}} или мањи',
	'open-ended-pledge':
		'Ова анкета садржи питања на која треба да одговорите својим речима. Ваши одговори биће аутоматски проверени да бисмо били сигурни да испуњавају захтеве квалитета. Да ли се слажете да наставимо?',
	'open-ended-pledge-accept': 'Да, слажем се',
	'open-ended-pledge-decline': 'Не, не слажем се',
	'rh-1-label': 'Шта од следеће наведеног није спорт?',
	'rh-1-o-1': 'Кошарка',
	'rh-1-o-2': 'Тенис',
	'rh-1-o-3': 'Колачић',
	'rh-1-o-4': 'Хокеј',
	'rh-2-label': 'Колико је 2+3?',
	'rh-3-label': 'Шта је од следеће наведеног воће?',
	'rh-3-o-1': 'Млеко',
	'rh-3-o-2': 'Чоколада',
	'rh-3-o-3': 'Јаје',
	'rh-3-o-4': 'Јабука',
	'open-end-max-length': 'Жао нам је, унети одговор је предугачак',
	'heatmap-click-image': 'Кликните на слику да додате коментар',
	'heatmap-click-image-no-comments': 'Кликните било где на слици',
	'heatmap-click-image-product-card': 'Кликните где желите да оставите коментар',
	'heatmap-click-image-product-card--no-comment': 'Кликните било где на картици',
	'heatmap-type-comment': 'Унесите коментар',
	cancel: 'Откажи',
	skip: 'Прескочи',
	ranked: 'Рангирано',
	top: 'Врх',
	'tap-or-drag': 'Додирните или превуците и отпустите за рангирање',
	unranked: 'Нерангирано',
	reset: 'Ресетуј',
	'no-more-options': 'Нема више опција за рангирање',
	'rank-list-full': 'Листа за рангирање је пуна, уклоните рангирање једног од ваших избора',
	'click-on-the-image-to-make-it-bigger': 'Кликните на слику да је повећате',
	thinking: 'Размишљам…',
	'ai-is-listening': 'AI слуша …',
	'please-tell-us-what-you-think': 'Кажите нам шта мислите о овој идеји …',
	'in-system-settings-open-privacy-and-security': 'У Подешавањима система, отворите Приватност и безбедност',
	'allow-access-to-your-camera': 'Дозволите приступ камери',
	'allow-access-to-your-microphone': 'Дозволите приступ микрофону',
	'click-the-camera-icon-in-browser-bar': 'Кликните на икону камере на горњој траци веб-прегледача',
	'we-would-love-for-you-to-participate': 'Желели бисмо да учествујете:',
	'your-video-will-only-be-used-internally':
		'Ваш видео ће се користити само за интерно истраживање и неће бити дељен са трећим странама.',
	'video-question-is-powered-by-ai': 'Питања о видеу је поставио AI, није потребна људска помоћ.',
	'responses-are-anonymous': 'Ваши одговори су анонимни и нису повезани са личним подацима.',
	'do-you-want-to-continue-participate': 'Желите ли да наставите учешће?',
	'skip-video-questions': 'Прескочи питања о видеу',
	'i-changed-my-mind': 'Предомислио/ла сам се',
	'are-you-sure-you-want-to-skip': 'Желите ли заиста да прескочите?',
	'to-continue-please-enable-camera-microphone':
		'Да бисте наставили, дозволите приступ камери и микрофону. Омогућите их одмах. Ако одлучите да га не омогућите, мораћемо да завршимо вашу сесију.',
	'yes-audio-only': 'Да, само звук',
	'yes-enable-access': 'Да, омогући приступ',
	'no-end-survey': 'Не, заврши сесију',
	'survey-contains-video-questions': 'Ова анкета садржи питања о видеу',
	'participate-video-response':
		'Ако се одлучите да учествујете, од вас ће се тражити да снимите видео-одговор помоћу камере и микрофона.',
	'i-got-it': 'Важи',
	'consent-to-video-responses':
		'Кликом на „Настави”, слажете се да доставите видео-одговоре и одобравате Upsiide и нашим клијентима неексклузивну лиценцу за коришћење, умножавање и дистрибуирање вашег видеа за интерно истраживање и анализу. Ова сагласност је неограничена осим ако се не повуче писменим путем.',
};
