// TODO: These methods are taken from Battleground and abstracted as a utility, if the end up being used for monadic it might make sense to use them in Battleground as well

import { getAssetVariationUrl } from './misc';

const hasDetails = (product) => product.title || product.description || product.price || product.subtitle;

const getDetails = (product) => ({
	type: 'Details',
	content: {
		title: product.title,
		summary: product.description,
		price: product.price,
		subtitle: product.subtitle,
	},
	options: {},
});

const getFieldSection = (type, content, options = {}, variationType = []) => {
	let fieldSection = null;
	const isTypeHtml = type === 'html';

	if (isTypeHtml) {
		fieldSection = {
			type: 'Text',
			content,
			options: options || {},
		};
	} else {
		let fieldContent = [];
		let isVideo = false;

		if (content && Array.isArray(content) && content.length > 0) {
			// Attempt to find the desired variation
			if (variationType.length) {
				if (variationType.includes('video')) {
					isVideo = true;
				}

				fieldContent = content?.map((contentItem) => {
					let contentItemFieldContent = [];

					if (contentItem.variations && contentItem.variations.length) {
						const filteredVariations = contentItem.variations.filter((assetVariation) =>
							variationType.includes(assetVariation.type),
						);

						const sortedTypes = filteredVariations.sort(
							(a, b) => variationType.indexOf(a.type) - variationType.indexOf(b.type),
						);

						const requestedVariation = sortedTypes.find((variation) =>
							variationType.includes(variation.type),
						);

						if (requestedVariation) {
							contentItemFieldContent = [requestedVariation.url];
						}
					}
					return contentItemFieldContent;
				});
			}

			// If fieldContent is still empty, set it to content url and mediaType
			if (fieldContent.length === 0) {
				fieldContent = content?.map((asset) => {
					if (asset.mediaType === 'video') {
						isVideo = true;
					}

					return getAssetVariationUrl(asset, isVideo ? ['video'] : ['large', 'full', 'medium', 'thumbnail']);
				});
			}
		}

		fieldSection = {
			type: isVideo ? 'Video' : 'Images',
			options: options || {},
			content: fieldContent,
		};
	}
	return fieldSection;
};

const getInvalidLayoutSections = (product) => {
	const invalidLayoutSections = [];
	if (product && product.fieldOne) {
		invalidLayoutSections[0] = getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions);
	}
	if (product && product.fieldTwo) {
		invalidLayoutSections[1] = getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions);
	}
	if (product && product.fieldThree) {
		invalidLayoutSections[2] = getFieldSection(
			product.fieldThreeType,
			product.fieldThree,
			product.fieldThreeOptions,
		);
	}
	return invalidLayoutSections;
};

const getStackProduct = (product, ideaScreenLayout, imageSize, imageCropping) => {
	const isImageOnly = ideaScreenLayout === 'imageOnly';
	const isTextOnly = ideaScreenLayout === 'textOnly';
	const isDoubleText = ideaScreenLayout === 'doubleText';
	const isNoTitle = ideaScreenLayout === 'noTitle';
	const isTitleTop = ideaScreenLayout === 'titleTop';
	const isTitleBottom = ideaScreenLayout === 'titleBottom';
	const isSideBySide = ideaScreenLayout === 'sideBySide';
	const isInvalidLayout = ideaScreenLayout === 'invalid_layout' || ideaScreenLayout === '';
	const invalidLayoutSections = getInvalidLayoutSections(product);
	const data = {
		type: 'Fixed',
		ideaScreenLayout,
		imageSize,
		imageCropping,
		...(product && product.layout && product.layout === 'classic'
			? {
					sections: [
						{
							type: 'Images',
							content: (product && product.fieldOne && product.fieldOne.map ? product.fieldOne : []).map(
								({ url, mediaType }) => ({ url, mediaType }),
							),
							options: product && product.fieldOneOptions ? product.fieldOneOptions : {},
						},
						...(hasDetails(product) ? [getDetails(product)] : []),
					],
			  }
			: {}),
		...(product && product.layout && product.layout === 'one-field'
			? {
					sections: [
						getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
						...(hasDetails(product) ? [getDetails(product)] : []),
					],
			  }
			: {}),
		...(product && product.layout && product.layout === 'two-field'
			? {
					sections: [
						getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
						...(product.fieldTwoType && product.fieldTwo
							? [getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions)]
							: []),
						...(hasDetails(product) ? [getDetails(product)] : []),
					],
			  }
			: {}),
		...(isInvalidLayout && {
			type: 'invalid_layout',
			sections: invalidLayoutSections,
		}),
		...(isImageOnly
			? {
					type: 'imageOnly',
					sections: [getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions)],
			  }
			: {}),
		...(isTextOnly
			? {
					type: 'textOnly',
					sections: [getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions)],
			  }
			: {}),
		...(isDoubleText
			? {
					type: 'doubleText',
					sections: [
						getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
						getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions),
					],
			  }
			: {}),
		...(isNoTitle
			? {
					type: 'noTitle',
					sections: [
						getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
						getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions),
					],
			  }
			: {}),
		...(isTitleTop
			? {
					type: 'titleTop',
					sections: [
						getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
						getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions),
						getFieldSection(product.fieldThreeType, product.fieldThree, product.fieldThreeOptions),
					],
			  }
			: {}),
		...(isTitleBottom
			? {
					type: 'titleBottom',
					sections: [
						getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
						getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions),
						getFieldSection(product.fieldThreeType, product.fieldThree, product.fieldThreeOptions),
					],
			  }
			: {}),
		...(isSideBySide
			? {
					type: 'sideBySide',
					sections: [
						getFieldSection(product.fieldOneType, product.fieldOne, product.fieldOneOptions),
						getFieldSection(product.fieldTwoType, product.fieldTwo, product.fieldTwoOptions),
						getFieldSection(product.fieldThreeType, product.fieldThree, product.fieldThreeOptions),
					],
			  }
			: {}),
	};
	return data;
};

const getCommitmentProduct = (products, ideaScreenLayout, imageSize, imageCropping, requestedVariation) => {
	const data = products.map((product) => {
		const isImageOnly = ideaScreenLayout === 'imageOnly';
		const isTextOnly = ideaScreenLayout === 'textOnly';
		const isDoubleText = ideaScreenLayout === 'doubleText';
		const isNoTitle = ideaScreenLayout === 'noTitle';
		const isTitleTop = ideaScreenLayout === 'titleTop';
		const isTitleBottom = ideaScreenLayout === 'titleBottom';
		const isSideBySide = ideaScreenLayout === 'sideBySide';
		const isInvalidLayout = ideaScreenLayout === 'invalid_layout';
		const invalidLayoutSections = getInvalidLayoutSections(product);
		return {
			id: product.id,
			ideaScreenLayout,
			imageSize,
			imageCropping,
			...(isInvalidLayout && {
				type: 'invalid_layout',
				sections: invalidLayoutSections,
				stackProduct: getStackProduct(product, ideaScreenLayout, imageSize, imageCropping),
			}),
			...(isImageOnly && {
				type: 'imageOnly',
				sections: [
					getFieldSection(
						product.fieldOneType,
						product.fieldOne,
						product.fieldOneOptions,
						requestedVariation,
					),
				],
				stackProduct: getStackProduct(product, ideaScreenLayout, imageSize, imageCropping),
			}),
			...(isTextOnly && {
				type: 'textOnly',
				sections: [
					getFieldSection(
						product.fieldOneType,
						product.fieldOne,
						product.fieldOneOptions,
						requestedVariation,
					),
				],
				stackProduct: getStackProduct(product, ideaScreenLayout, imageSize, imageCropping),
			}),
			...(isDoubleText && {
				type: 'doubleText',
				sections: [
					getFieldSection(
						product.fieldOneType,
						product.fieldOne,
						product.fieldOneOptions,
						requestedVariation,
					),
				],
				stackProduct: getStackProduct(product, ideaScreenLayout, imageSize, imageCropping),
			}),
			...(isNoTitle && {
				type: 'noTitle',
				sections: [
					getFieldSection(
						product.fieldOneType,
						product.fieldOne,
						product.fieldOneOptions,
						requestedVariation,
					),
				],
				stackProduct: getStackProduct(product, ideaScreenLayout, imageSize, imageCropping),
			}),
			...(isTitleTop && {
				type: 'titleTop',
				sections: [
					getFieldSection(
						product.fieldOneType,
						product.fieldOne,
						product.fieldOneOptions,
						requestedVariation,
					),
					getFieldSection(
						product.fieldTwoType,
						product.fieldTwo,
						product.fieldTwoOptions,
						requestedVariation,
					),
				],
				stackProduct: getStackProduct(product, ideaScreenLayout, imageSize, imageCropping),
			}),
			...(isTitleBottom && {
				type: 'titleBottom',
				sections: [
					getFieldSection(
						product.fieldOneType,
						product.fieldOne,
						product.fieldOneOptions,
						requestedVariation,
					),
					getFieldSection(
						product.fieldTwoType,
						product.fieldTwo,
						product.fieldTwoOptions,
						requestedVariation,
					),
				],
				stackProduct: getStackProduct(product, ideaScreenLayout, imageSize, imageCropping),
			}),
			...(isSideBySide && {
				type: 'sideBySide',
				sections: [
					getFieldSection(
						product.fieldOneType,
						product.fieldOne,
						product.fieldOneOptions,
						requestedVariation,
					),
					getFieldSection(
						product.fieldTwoType,
						product.fieldTwo,
						product.fieldTwoOptions,
						requestedVariation,
					),
				],
				stackProduct: getStackProduct(product, ideaScreenLayout, imageSize, imageCropping),
			}),
		};
	});

	return data;
};

export default {
	hasDetails,
	getDetails,
	getFieldSection,
	getInvalidLayoutSections,
	getStackProduct,
	getCommitmentProduct,
};
