export default {
	'this-survey-does-not-exist': 'Valitettavasti tätä kyselyä ei ole olemassa.',
	'survey-closed': 'Valitettavasti tämä kysely on nyt suljettu.',
	'survey-no-products': 'Tässä kyselyssä ei ole tuotteita.',
	'survey-no-products-summary': 'Jos olet tämän kyselyn omistaja, jatka luomalla tuotteita hallintapaneelissa.',
	'survey-completed': 'Olet jo osallistunut tähän kyselyyn.',
	'summary-placeholder': 'Jos uskot, että tämä on virhe, ota yhteyttä henkilöön, joka lähetti sinulle tämän linkin.',
	'gone-wrong': 'Hm, jotakin on vialla.',
	'try-again': 'Yritä uudelleen tai tule takaisin myöhemmin.',
	'thank-you': 'Kiitos',
	'thank-you-summary': 'Kyselysi on valmis, ja tulokset on lähetetty.',
	'thank-you-summary-redirect': 'Sinut ohjataan uudelleen muutaman sekunnin kuluttua.',
	'page-not-found': 'Jotakin on vialla',
	'page-not-found-placeholder': 'Oho, rikkinäinen linkki',
	'tap-your-favorite': 'Oho, rikkinäinen linkki',
	or: 'tai',
	'get-started-with-questions': 'Muutama kysymys päästäksemme alkuun',
	continue: 'Jatka',
	retry: 'Yritä uudelleen',
	'tell-us-how-you-feel-about-this-product': 'Kerro meille, mitä ajattelet tästä tuotteesta.',
	begin: 'Aloita',
	'how-would-you-describe-this-product-to-a-friend': 'Kuinka kuvailisit tätä tuotetta ystävällesi?',
	'type-your-response-here': 'Kirjoita vastauksesi tähän',
	'sum-of-total-characters': '${SUM}/${TOTAL} merkkiä',
	edit: 'Muokkaa',
	remove: 'Poista',
	'how-do-you-feel-about-this-part': 'Mitä mieltä olet tästä osasta?',
	'tap-on-an-area-and-choose-an-emoji': 'Napauta aluetta ja valitse emoji',
	'im-finished': 'Olen valmis',
	maintenance: 'Ylläpito',
	'maintenance-mode': 'Upsiide suorittaa tällä hetkellä järjestelmän ylläpitoa. Tarkista tilanne pian uudelleen.',
	'please-specify': 'Tarkenna',
	'monadic-split-statement':
		'Seuraavassa näytössä näytämme sinulle idean. Tutki sitä huolellisesti, sillä meillä on kysymyksiä siitä.',
	disapproval: 'Paheksunta',
	anger: 'Viha',
	sad: 'Surullinen',
	joy: 'Ilo',
	love: 'Rakkaus',
	trust: 'Luottamus',
	hope: 'Toivo',
	proud: 'Ylpeä',
	amazed: 'Hämmästynyt',
	surprised: 'Yllättynyt',
	guilt: 'Syyllisyys',
	curious: 'Utelias',
	jealous: 'Kateellinen',
	anticipation: 'Innokas odotus',
	optimistic: 'Optimistinen',
	anxious: 'Levoton',
	fear: 'Pelko',
	despair: 'Epätoivo',
	disbelief: 'Epäusko',
	cynicism: 'Kyynisyys',
	regret: 'Katumus',
	disgust: 'Inho',
	annoyed: 'Ärsyyntynyt',
	aggressive: 'Aggressiivinen',
	shocked: 'Järkyttynyt',
	choose: 'Valitse {{amount}}',
	'up-to': 'Valitse enintään {{amount}}',
	unlimited: 'Valitse niin monta kuin haluat',
	'single-select': 'Valitse yksi',
	number: 'Anna numero',
	'number-between-min-max': 'Anna numero väliltä {{min}} ja {{max}}',
	'number-min': 'Anna numero {{min}} tai suurempi',
	'number-max': 'Anna numero {{max}} tai pienempi',
	'open-ended-pledge':
		'Tämä kysely sisältää kysymyksiä, joihin sinua pyydetään vastaamaan omin sanoin. Vastauksesi tarkistetaan automaattisesti, jotta voimme varmistaa, että ne vastaavat laatuvaatimuksiamme. Suostutko jatkamaan?',
	'open-ended-pledge-accept': 'Kyllä, suostun',
	'open-ended-pledge-decline': 'Ei, en suostu',
	'rh-1-label': 'Mikä seuraavista ei ole urheilua?',
	'rh-1-o-1': 'Koripallo',
	'rh-1-o-2': 'Tennis',
	'rh-1-o-3': 'Pikkuleipä',
	'rh-1-o-4': 'Jääkiekko',
	'rh-2-label': 'Paljonko on 2 + 3?',
	'rh-3-label': 'Mikä seuraavista on hedelmä?',
	'rh-3-o-1': 'Maito',
	'rh-3-o-2': 'Suklaa',
	'rh-3-o-3': 'Kananmuna',
	'rh-3-o-4': 'Omena',
	'open-end-max-length': 'Valitettavasti annettu vastaus on liian pitkä',
	'heatmap-click-image': 'Lisää kommentti napsauttamalla kuvaa',
	'heatmap-click-image-no-comments': 'Napsauta mitä tahansa kohtaa kuvassa',
	'heatmap-click-image-product-card': 'Napsauta kohtaa, johon haluat jättää kommentin',
	'heatmap-click-image-product-card--no-comment': 'Napsauta mitä tahansa kohtaa kortissa',
	'heatmap-type-comment': 'Kirjoita kommentti',
	cancel: 'Peruuta',
	skip: 'Ohita',
	ranked: 'Arvioitu',
	top: 'Paras',
	'tap-or-drag': 'Napauta tai vedä ja pudota arvioidaksesi',
	unranked: 'Arvioimaton',
	reset: 'Nollaa',
	'no-more-options': 'Ei enempää vaihtoehtoja arvioitavaksi',
	'rank-list-full': 'Arviointilista on täynnä, poista yhden valinnan arviointi',
	'click-on-the-image-to-make-it-bigger': 'Suurenna kuvaa napsauttamalla sitä',
	thinking: 'Mietitään…',
	'ai-is-listening': 'Tekoäly kuuntelee…',
	'please-tell-us-what-you-think': 'Kerro meille, mitä ajattelet tästä ideasta…',
	'in-system-settings-open-privacy-and-security': 'Avaa Järjestelmäasetuksissa Tietosuoja ja -turva',
	'allow-access-to-your-camera': 'Salli pääsy kameraasi',
	'allow-access-to-your-microphone': 'Salli pääsy mikrofoniisi',
	'click-the-camera-icon-in-browser-bar': 'Napsauta kamerakuvaketta selaimen yläpalkissa',
	'we-would-love-for-you-to-participate': 'Meistä olisi upeaa, jos osallistut:',
	'your-video-will-only-be-used-internally':
		'Videotasi käytetään vain sisäiseen tutkimukseen eikä sitä jaeta kolmansille osapuolille.',
	'video-question-is-powered-by-ai': 'Videokysymys on tekoälyn suorittama eikä vaadi ihmisen vuorovaikutusta.',
	'responses-are-anonymous': 'Vastauksesi ovat nimettömiä, eikä niitä yhdistetä henkilötietoihin.',
	'do-you-want-to-continue-participate': 'Haluatko jatkaa osallistumista?',
	'skip-video-questions': 'Ohita videokysymykset',
	'i-changed-my-mind': 'Muutin mieleni',
	'are-you-sure-you-want-to-skip': 'Oletko varma, että haluat ohittaa sen?',
	'to-continue-please-enable-camera-microphone':
		'Jotta voisimme jatkaa, pyydämme ystävällisesti sallimaan pääsyn kameraasi ja mikrofoniisi. Aktivoi nyt niihin pääsy. Jos et halua sallia pääsyä, meidän on päätettävä istuntosi.',
	'yes-audio-only': 'Kyllä, vain ääni',
	'yes-enable-access': 'Kyllä, aktivoi pääsy',
	'no-end-survey': 'Ei, lopeta kysely',
	'survey-contains-video-questions': 'Tämä kysely sisältää videokysymyksiä',
	'participate-video-response':
		'Jos päätät osallistua, sinua pyydetään nauhoittamaan videovastaus kameraa ja mikrofonia käyttämällä.',
	'i-got-it': 'Ymmärrän.',
	'consent-to-video-responses':
		'Napsauttamalla ”Jatka” annat suostumuksesi videovastausten antamiseen ja myönnät Upsiidelle ja asiakkaillemme rajoittamattoman lisenssin käyttää, jäljentää ja jakaa videotasi sisäiseen tutkimukseen ja analyysiin. Tämä suostumus on voimassa toistaiseksi, ellei sitä peruta kirjallisesti.',
};
