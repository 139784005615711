export default {
	'survey-closed': 'Nažalost, ova je anketa sada zatvorena.',
	'survey-no-products': 'Ova anketa ne sadrži proizvode.',
	'survey-no-products-summary': 'Ako ste vlasnik ove ankete, za nastavak kreirajte proizvode na nadzornoj ploči.',
	'survey-completed': 'Već ste dovršili ovu anketu.',
	'summary-placeholder': 'Ako smatrate da je riječ o grešci, obratite se osobi koja vam je poslala ovu poveznicu.',
	'gone-wrong': 'Hmmm, nešto nije u redu.',
	'try-again': 'Pokušajte ponovno ili se vratite kasnije.',
	'thank-you': 'Hvala',
	'thank-you-summary': 'Anketa je dovršena i vaši su odgovori podneseni',
	'thank-you-summary-redirect': 'Preusmjerit ćemo vas za nekoliko sekundi.',
	'page-not-found': 'Nešto nije u redu',
	'page-not-found-placeholder': 'Ups, poveznica je prekinuta',
	'tap-your-favorite': 'Dodirnite kvačicu uz omiljeno',
	or: 'ili',
	'get-started-with-questions': 'Nekoliko pitanja za početak',
	continue: 'Nastavi',
	retry: 'Pokušaj ponovno',
	'tell-us-how-you-feel-about-this-product': 'Recite nam što mislite o ovom proizvodu',
	begin: 'Započni',
	'how-would-you-describe-this-product-to-a-friend': 'Kako biste ovaj proizvod opisali svojem prijatelju?',
	'type-your-response-here': 'Upišite svoj odgovor ovdje',
	'sum-of-total-characters': '${SUM} od ukupnog broja znakova (${TOTAL})',
	edit: 'Uredi',
	remove: 'Ukloni',
	'how-do-you-feel-about-this-part': 'Što mislite o ovom dijelu?',
	'tap-on-an-area-and-choose-an-emoji': 'Dodirnite područje i odaberite emotikon',
	'im-finished': 'Dovršeno',
	maintenance: 'Održavanje',
	'maintenance-mode': 'Upsiide je trenutačno u fazi održavanja. Provjerite ponovno ubrzo.',
	'please-specify': 'Navedite',
	'monadic-split-statement':
		'Na sljedećem zaslonu prikazat ćemo vam pojam. Pažljivo ga razmotrite jer ćemo vam postaviti pitanja o njemu.',
	disapproval: 'Neodobravanje',
	anger: 'Ljutnja',
	sad: 'Tuga',
	joy: 'Radost',
	love: 'Ljubav',
	trust: 'Povjerenje',
	hope: 'Nada',
	proud: 'Ponos',
	amazed: 'Oduševljenost',
	surprised: 'Iznenađenost',
	guilt: 'Krivnja',
	curious: 'Znatiželja',
	jealous: 'Ljubomora',
	anticipation: 'Iščekivanje',
	optimistic: 'Optimističnost',
	anxious: 'Tjeskoba',
	fear: 'Strah',
	despair: 'Očaj',
	disbelief: 'Nevjerica',
	cynicism: 'Ciničnost',
	regret: 'Žaljenje',
	disgust: 'Gađenje',
	annoyed: 'Iznerviranost',
	aggressive: 'Agresivnost',
	shocked: 'Zapanjenost',
	choose: 'Odaberite {{amount}}',
	'up-to': 'Odaberite do {{amount}}',
	unlimited: 'Odaberite bilo koji iznos',
	'single-select': 'Odaberite jedno',
	number: 'Unesite broj',
	'number-between-min-max': 'Unesite broj od {{min}} do {{max}}',
	'number-min': 'Unesite broj {{min}} ili veći',
	'number-max': 'Unesite broj {{max}} ili manji',
	'open-ended-pledge':
		'Ova anketa sadrži pitanja na koja trebate odgovoriti svojim riječima. Vaši će se odgovori automatski provjeriti kako bismo bili sigurni da ispunjavaju naše zahtjeve kvalitete. Pristajete li nastaviti?',
	'open-ended-pledge-accept': 'Da, pristajem',
	'open-ended-pledge-decline': 'Ne, ne pristajem',
	'rh-1-label': 'Što od navedenog nije sport?',
	'rh-1-o-1': 'Košarka',
	'rh-1-o-2': 'Tenis',
	'rh-1-o-3': 'Kolačić',
	'rh-1-o-4': 'Hokej',
	'rh-2-label': 'Koliko je 2 + 3?',
	'rh-3-label': 'Što je od navedenog voće?',
	'rh-3-o-1': 'Mlijeko',
	'rh-3-o-2': 'Čokolada',
	'rh-3-o-3': 'Jaje',
	'rh-3-o-4': 'Jabuka',
	'open-end-max-length': 'Nažalost, uneseni je odgovor predug',
	'heatmap-click-image': 'Kliknite na sliku kako biste dodali napomenu',
	'heatmap-click-image-no-comments': 'Kliknite bilo gdje na slici',
	'heatmap-click-image-product-card': 'Kliknite mjesto gdje želite ostaviti napomenu',
	'heatmap-click-image-product-card--no-comment': 'Kliknite bilo gdje na kartici',
	'heatmap-type-comment': 'Upišite napomenu',
	cancel: 'Otkaži',
	skip: 'Preskoči',
	ranked: 'Rangirano',
	top: 'Vrh',
	'tap-or-drag': 'Dodirnite ili povucite i ispustite za rangiranje',
	unranked: 'Nerangirano',
	reset: 'Ponovo postavi',
	'no-more-options': 'Nema dodatnih opcija za rangiranje',
	'rank-list-full': 'Popis rangiranja je pun; poništite rangiranje jednog od vaših izbora',
	'click-on-the-image-to-make-it-bigger': 'Kliknite na sliku kako biste je povećali',
	thinking: 'Razmišljanje…',
	'ai-is-listening': 'Umjetna inteligencija sluša…',
	'please-tell-us-what-you-think': 'Recite nam što mislite o ovoj ideji…',
	'in-system-settings-open-privacy-and-security': 'U postavkama sustava otvorite Privatnost i sigurnost',
	'allow-access-to-your-camera': 'Omogućite pristup svojoj kameri',
	'allow-access-to-your-microphone': 'Omogućite pristup svojem mikrofonu',
	'click-the-camera-icon-in-browser-bar': 'Kliknite ikonu kamere na gornjoj traci web-preglednika',
	'we-would-love-for-you-to-participate': 'Bilo bi nam drago da sudjelujete:',
	'your-video-will-only-be-used-internally':
		'Vaš će se videozapis koristiti samo za interno istraživanje i neće se dijeliti s trećim stranama.',
	'video-question-is-powered-by-ai':
		'Videopitanje pokreće umjetna inteligencija, pa ne zahtijeva ljudsku interakciju.',
	'responses-are-anonymous': 'Vaši su odgovori anonimni i nisu povezani s osobnim podacima.',
	'do-you-want-to-continue-participate': 'Želite li i dalje sudjelovati?',
	'skip-video-questions': 'Preskoči videopitanja',
	'i-changed-my-mind': 'Predomislio/la sam se',
	'are-you-sure-you-want-to-skip': 'Jeste li sigurni da želite preskočiti?',
	'to-continue-please-enable-camera-microphone':
		'Za nastavak nam je potreban pristup vašoj kameri i mikrofonu. Omogućite ga sada. Ako to ne učinite, morat ćemo prekinuti vašu sesiju.',
	'yes-audio-only': 'Da, samo zvuk',
	'yes-enable-access': 'Da, omogući pristup',
	'no-end-survey': 'Ne, završi anketu',
	'survey-contains-video-questions': 'Ova anketa sadrži videopitanja',
	'participate-video-response':
		'Ako odlučite sudjelovati, od vas će se tražiti da snimite videoodgovor pomoću kamere i mikrofona.',
	'i-got-it': 'Razumijem',
	'consent-to-video-responses':
		'Klikom na „Nastavi” pristajete na pružanje videoodgovora i dajete Upsiideu i našim klijentima neekskluzivnu licencu za korištenje, reprodukciju i distribuciju vašeg videozapisa za interno istraživanje i analizu. Ova privola vrijedi neograničeno, osim ako se ne povuče u pisanom obliku.',
};
