import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ar from './domains/translations/ar';
import deDE from './domains/translations/de_DE';
import elGR from './domains/translations/el_GR';
import enCA from './domains/translations/en_CA';
import enUK from './domains/translations/en_UK';
import enUS from './domains/translations/en_US';
import enGB from './domains/translations/en_GB';
import esES from './domains/translations/es_ES';
import frCA from './domains/translations/fr_CA';
import frFR from './domains/translations/fr_FR';
import heIL from './domains/translations/he_IL';
import hiIN from './domains/translations/hi_IN';
import hu from './domains/translations/hu';
import id from './domains/translations/id';
import it from './domains/translations/it';
import jp from './domains/translations/jp';
import ko from './domains/translations/ko';
import msMY from './domains/translations/ms_MY';
import nl from './domains/translations/nl';
import pl from './domains/translations/pl';
import ptBR from './domains/translations/pt_BR';
import ptPT from './domains/translations/pt_PT';
import ro from './domains/translations/ro';
import ru from './domains/translations/ru';
import sw from './domains/translations/sw';
import thTH from './domains/translations/th_TH';
import tl from './domains/translations/tl';
import tr from './domains/translations/tr';
import ur from './domains/translations/ur';
import viVN from './domains/translations/vi_VN';
import zhCN from './domains/translations/zh_CN';
import zhHA from './domains/translations/zh_HA';
import bg from './domains/translations/bg';
import cs from './domains/translations/cs';
import da from './domains/translations/da';
import sv from './domains/translations/sv';
import fi from './domains/translations/fi';
import hr from './domains/translations/hr';
import no from './domains/translations/no';
import sr from './domains/translations/sr';

const defaultLanguage = 'en';

i18n.use(initReactI18next).init({
	interpolation: { escapeValue: false },
	lng: defaultLanguage,
	resources: {
		ar,
		de: deDE,
		en: enUS,
		es: esES,
		fr: frFR,
		el: elGR,
		he: heIL,
		hi: hiIN,
		hu,
		id,
		it,
		jp,
		nl,
		ro,
		ru,
		sw,
		ko,
		ms: msMY,
		nl,
		pl,
		pt: ptPT,
		th: thTH,
		tl,
		tr,
		ur,
		zhHA,
		vi: viVN,
		vn: viVN, // * temporary hotfix: duplicate of vi code
		zh: zhCN,
		bg,
		cs,
		da,
		sv,
		fi,
		hr,
		no,
		sr,

		// Deprecated
		de_DE: deDE,
		en_US: enUS,
		en_CA: enCA,
		en_ZA: enCA,
		en_UK: enUK,
		en_NL: enUK,
		en_DK: enUK,
		en_GB: enGB,
		es_ES: esES,
		fr_CA: frCA,
		fr_FR: frFR,
		he_IL: heIL,
		ko_KR: ko,
		ms_MY: msMY,
		pt_BR: ptBR,
		pt_PT: ptPT,
		th_TH: thTH,
		vi_VN: viVN,
		zh_CN: zhCN,
	},
});

i18n.isRTL = function () {
	if (['he', 'he_IL', 'ar', 'ur'].indexOf(this.language) > -1) {
		return true;
	}
};

export default i18n;
