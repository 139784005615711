// packages
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// assets
import NoCameraIcon from 'public/images/no-camera.svg';

// utilities
import cn from 'src/utilities/bem-cn';

// hooks
import useIsMobile from 'src/utilities/hooks/useIsMobile';

// styles
import './styles.scss';

const className = 'video-interactor-recorder';

const FREQUENCY_BIN_COUNT = 2 ** 5;

const el = (name, mod) => cn(className, name, mod);

const VideoRecorder = ({
	isVoiceDetected,
	showImage,
	demoImageURL,
	squareWrapperSideSize,
	isVideoMirrored,
	canvasRef,
	frontCamera,
	debug = false,
}) => {
	// state
	const isMobile = useIsMobile();
	const hasSelectedAudioOnly = useSelector((state) => state?.main?.audioOnly);
	const opacityShowImage = showImage ? 0 : 1;

	const renderBarBins = () => {
		const indexes = Array(FREQUENCY_BIN_COUNT)
			.fill(0)
			.map((_, i) => i)
			.sort((a, b) => {
				const aOdd = a % 2 === 0;
				const bOdd = b % 2 === 0;
				if (aOdd === bOdd) return aOdd ? a - b : b - a;
				return aOdd ? 1 : -1;
			});
		return indexes.map((index) => <div className={el('frequency-bar')} id={`bin-${index}`} key={`bin-${index}`} />);
	};

	return (
		<>
			{debug && (
				<div
					{...{ className }}
					style={{ width: squareWrapperSideSize ?? 'auto', height: squareWrapperSideSize ?? 'auto' }}
				>
					<canvas
						className={el('canvas-video')}
						ref={canvasRef}
						// eslint-disable-next-line no-nested-ternary
						style={{
							opacity: isMobile || hasSelectedAudioOnly ? 0 : opacityShowImage,
							border: '1px dashed red',
						}}
					/>
				</div>
			)}
			<div
				{...{ className }}
				style={{ width: squareWrapperSideSize ?? 'auto', height: squareWrapperSideSize ?? 'auto' }}
			>
				{isVoiceDetected && <div className={el('active-border')} />}

				{isMobile && (
					<div
						className={el('image-overlay-mobile')}
						style={{
							opacity: showImage ? 1 : 0,
						}}
					>
						<img src={demoImageURL} alt="demo" />
					</div>
				)}

				{hasSelectedAudioOnly && (
					<div className={el('no-camera-container')}>
						<div className={el('no-camera-img-container')}>
							<img src={NoCameraIcon} alt="No camera" className={el('no-camera-img')} />
						</div>
					</div>
				)}

				<div className={el('audio-feedback-circle')}>{renderBarBins()}</div>

				{!debug && (
					<canvas
						className={el('canvas-video')}
						ref={canvasRef}
						// eslint-disable-next-line no-nested-ternary
						style={{ opacity: isMobile || hasSelectedAudioOnly ? 0 : opacityShowImage }}
					/>
				)}

				<video
					ref={frontCamera}
					playsInline
					type="video/mp4"
					autoPlay
					disablePictureInPicture
					controlsList="nodownload"
					disableRemotePlayback
					className={el('video-context')}
					// className={el('video-context', isVideoMirrored && 'mirrored')}
					muted
				>
					Your browser does not support the video tag.
				</video>
			</div>
		</>
	);
};

VideoRecorder.propTypes = {
	isVoiceDetected: PropTypes.bool,
	showImage: PropTypes.bool,
	demoImageURL: PropTypes.string,
	squareWrapperSideSize: PropTypes.number,
	isVideoMirrored: PropTypes.bool,
	canvasRef: PropTypes.any,
	frontCamera: PropTypes.any,
	debug: PropTypes.bool,
};

export default VideoRecorder;
